/* Promo */

#Promo_cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}
#Promo_cont.active {
  display: flex;
}
#Promo_cont .container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 1rem 1rem;
  background: var(--btn-5);
  border-radius: .3rem;
}
.closeyt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: .5rem 0;
  transition: .25s ease;
  cursor: pointer;
}
.closeyt:hover {
  background: var(--btn-4);
}
.closeyt i {
  font-size: 2.4rem;
}
#Promo_cont .container iframe {
  width: 950px;
  height: 534px;
  border-radius: .5rem;
}

@media (max-width: 991px) {
  #Promo_cont .container iframe {
    width: 700px;
    height: 394px;
    border-radius: .5rem;
  }
}
@media (max-width: 768px) {
  #Promo_cont .container {
    padding: 0 1rem 1rem;
  }
  #Promo_cont .container iframe {
    width: 450px;
    height: 253px;
    border-radius: .5rem;
  }
}
@media (max-width: 520px) {
  #Promo_cont .container {
    padding: 0 .5rem 1rem;
  }
  #Promo_cont .container iframe {
    width: 340px;
    height: 191px;
    border-radius: .5rem;
  }
}
@media (max-width: 412px) {
  #Promo_cont .container iframe {
    width: 412px;
    height: 232px;
    border-radius: 1px;
  }
  .closeyt {
    width: 2.1rem;
    height: 2.1rem;
  }
}
@media (max-width: 371px) {
  #Promo_cont .container iframe {
    width: 371px;
    height: 209px;
    border-radius: 1px;
  }
}