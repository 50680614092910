
:root {
  --bg-color: linear-gradient(180deg, #ffffff 0%, #d4dfef 100%);
  --bg-2: #FAFAFA;
  --bg-3: #ffffff;
  --bg-4: #F1F2FF;
  --txt-1: #424d60;
  --txt-2: #eef0f4;
  --txt-grad-1: linear-gradient(90deg, #b98fc8 0%, #26b3de 100%);
  --txt-grad-2: linear-gradient(13deg, #406aff 0%, #f7666f 100%);
  --btn-1: #5021FF;
  --btn-2: #f7666f;
  --btn-3: #d4dfef;
  --btn-4: #DCB3E3;
  --btn-5: #D1E1F0;
  --btn-6: #35B0D7;
  --btn-7: #fd9ca2;
}
/* Cart */
#Cart {
  padding: 5rem 7%;
  min-height: unset;
}
.cart_holder .top_cont {
  width: 100%;
  height: 3rem;
  margin: 3rem 0 1rem;
  display: flex;
  justify-content: flex-end;
}
.cart_holder .top_cont .btn-cont {
  gap: 1.5rem;
}
.cart_holder .carts_cont {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: flex-end;
}
.cart_holder .left_cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.cart_holder .left_cont .cart {
  /* width: 65%; */
  background: var(--bg-4);
  display: flex;
  align-items: center;
  padding-right: 1rem;
  border: .9px solid var(--btn-7);
  border-radius: .2rem;
  display: none;
}
.cart_holder .left_cont .cart.active {
  display: flex;
}
.cart_holder .left_cont .cart.active .cart_name {
  user-select: none;
  width: 100%;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 500;
}
.cart .cart_left {
  display: flex;
  width: 100%;
  gap: .6rem;
}
.i_cont box-icon {
  cursor: pointer;
  /* margin: 0 .5rem; */
}
.cart_holder .left_cont .cart .img_cont {
  width: 7rem;
  height: 4.5rem;
  padding: .5rem;
  background: var(--btn-3);
  user-select: none;
}
.cart_holder .left_cont .cart img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart_holder .cart_right {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .5rem;
}
.cart_holder .cart_right .i_cont {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  gap: 1.4rem;
}
.cart_holder .cart_right p,
.cart_holder .cart_right box-icon {
  align-self: center;
  user-select: none;
}

.cart_holder .right_cont {
  height: max-content;
  width: 100%;
  border-radius: .2rem;
}
.cart_holder .right_cont .p_cont{
  width: 100%;
  text-align: center;
  padding: .8rem .9rem;
  border-radius: .2rem;
  background: var(--bg-4);
  margin-bottom: .1rem;
}
.info_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .3rem;
}
.info_cont .left_info,
.info_cont .right_info {
  flex: 1;
  display: flex;
  border: 0.5px solid var(--btn-7);
}
.info_cont .left_info div,
.info_cont .right_info  div{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.info_cont .left_info div p,
.info_cont .right_info  div p{
  padding: .8rem .9rem;
  background: var(--bg-4);
  margin-bottom: .1rem;
  min-width: max-content;
}
.form_cont {
  max-width: 38rem;
  width: 89%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  z-index: 100;
  padding: 1rem;
  border-radius: .3rem;
  background: var(--bg-2);
  box-shadow: 5px 5px 5px 0px #00000026,
  -2px -3px 10px -1px #00000026;
  display: none;
}
.form_cont.active {
  display: block;
}
.form_cont .icon-cont {
  width: 100%;
  display: flex;
 justify-content: flex-end;
}
.form_cont .icon-cont svg {
  font-size: 3rem;
  padding: .5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: .25s;
}
.form_cont .icon-cont svg:hover {
  background: var(--btn-7);
}
a.nohover:hover::before {
  color: var(--txt-2);
  width: 100%;
}
a.nohover:hover {
  color: var(--txt-2);
}
.form_cont #choosendiv {
  width: 3.7rem;
  height: 2.7rem;
  padding: .2rem;
  background: var(--btn-5);
  border-radius: 1px;
  display: flex;
}
.form_cont #choosendiv img {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  align-self: center;
  object-fit: contain;
}
#cart .btn-cont .nohover {
  display: unset;
}
.form_cont .btn-cont {
  gap: .4rem;
}
.form_cont .btn-cont .btn {
  background-color: var(--btn-1);
  color: var(--txt-2);
  cursor: pointer;
}
.form_cont .btn-cont label.btn.null {
  border: 2px solid red;
  color: red;
  background: transparent;
}
.form_cont .btn-cont label.btn.null::before {
  display: none;
}
.form_cont .icon-cont box-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: .2rem;
  transition: .25s;
  cursor: pointer;
}
.form_cont .icon-cont box-icon:hover {
  background: var(--btn-2);
}
.form_cont .btn-cont .btn_none {
  display: none;
}


/* Screen Size */
@media (max-width: 1200px) { 
  #Cart {
    padding: 5rem 6%;
  }
}
@media (max-width: 991px) { 
  #Cart {
    padding: 5rem 5%;
  }
  .cart_holder .carts_cont {
    gap: 2rem;
    flex-direction: column;
    align-items: flex-end;
  }
  .cart .cart_left p {
    text-align: unset;
  }
}
@media (max-width: 890px) {
  .info_cont {
    flex-direction: column;
  }
}
@media (max-width: 800px) {
  .cart_holder .cart_right .i_cont {
    gap: .5rem;
  }
}
@media (max-width: 768px) {
  #Cart {
    padding: 5rem 2%;
  }
  .cart .cart_left p{
    font-size: .9rem;
  }
  .cart_holder .cart_right p {
    font-size: .9rem;
  }
  .info_cont .left_info div:first-child p:first-child,
  .info_cont .right_info  div:first-child p:first-child {
    min-width: unset;
    width: 10rem;
  }
  .info_cont .left_info  div:first-child,
  .info_cont .right_info  div:first-child {
    flex: unset;
    width: max-content;
  }
}
@media (max-width: 601px) {
  .cart_holder {
    padding: 0 5%;
  }
  .cart_holder .left_cont .cart {
    flex-direction: column;
  }
  .cart .cart_left {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .cart_holder .left_cont .cart .img_cont {
    width: 22rem;
    height: 14rem;
    background-color: transparent;
  }
  .cart .cart_left p {
    font-size: 1.2rem;
    max-width: 100%;
    text-align: center;
  }
  .cart_holder .cart_right {
    padding: 1rem;
    gap: unset;
  }
  .cart_holder .right_cont {
    width: 100%;
  }
  .cart_holder .cart_right p,
  .i_cont {
    font-size: 14px;
  }
  .cart_holder .left_cont .cart.active {
    padding-right: 0;
  }
  .info_cont .left_info div:first-child p:first-child, 
  .info_cont .right_info div:first-child p:first-child {
    width: 100%;
  }
  .cart_holder .cart_right .i_cont {
    gap: 1.5rem;
  }
}
@media (max-width: 520px) {
  .form_cont .btn-cont .btn,
  #Cart .btn
   {
    padding: 0.4rem 0.5rem;
    font-size: .8rem;
    letter-spacing: unset;
  }
  .info_cont p {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .info_cont p {
    font-size: 13px;
  }
  .cart_holder .cart_right box-icon {
    width: 20px;
  }
}