#languageSelect {
  width: 5rem;
  height: 2rem;
  background: transparent;
  border: none;
  font-size: 15px;
  margin-left: 2rem;
}
#languageSelect:focus {
  outline: none;
  border-color: initial;
}
@media (max-width: 1120px) {
  #languageSelect {
    margin-left: 1.2rem;
  }
}
@media (max-width: 991px) {
  #languageSelect {
    margin-left: .7rem;
    font-size: 13px;
    width: 4rem;
  }
}
@media (max-width: 813px) {
  #languageSelect{
    margin: 0;
    font-size: 1.1rem;
    width: 5rem;
  }
}
