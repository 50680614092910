
@import url('https://fonts.googleapis.com/css2?family=Inspiration&family=Kaushan+Script&family=Orbitron:wght@400;500&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700&display=swap');

@import url('https://fonts.cdnfonts.com/css/conthrax');


body {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  font-family: "Poppins", "sans-serif";
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
  transition: 0.15s;
}
section {
  padding: 0 9% 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Poppins', 'sans-serif';
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  color: var(--txt-1);
}
a, button {
  cursor: pointer;
}
:root {
  --bg-color: linear-gradient(180deg, #ffffff 0%, #d4dfef 100%);
  --bg-2: #FAFAFA;
  --bg-3: #ffffff;
  --bg-4: #F1F2FF;
  --txt-1: #424d60;
  --txt-2: #eef0f4;
  --txt-grad-1: linear-gradient(90deg, #b98fc8 0%, #26b3de 100%);
  --txt-grad-2: linear-gradient(13deg, #406aff 0%, #f7666f 100%);
  --btn-1: #5021FF;
  --btn-2: #f7666f;
  --btn-3: #d4dfef;
  --btn-4: #DCB3E3;
  --btn-5: #D1E1F0;
  --btn-6: #35B0D7;
  --btn-8: #15255E;
}
.animate {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--txt-2);
  transition: .8s ease;
  transition-delay: calc(.3s * var(--i));
  z-index: 100;
}
.start_animate span.animate {
  width: 0 !important;
}
.btn-cont {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  gap: 3rem;
}
.btn-cont .btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0.6rem 1rem;
  width: fit-content;
  height: fit-content;
  border: .1rem solid var(--btn-1);
  border-radius: .4rem;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .1rem;
  color: var(--txt-2);
  z-index: 1;
  overflow: hidden;
  transition: .5s;
  background: transparent;
  transition: .15s;
}
.btn:hover {
  color: var(--txt-1);
}
.btn:nth-child(2) {
  background: transparent;
  color: var(--txt-1);
}
.empty_btn {
  background: transparent;
  color: var(--txt-1);
}
.btn:nth-child(2):hover {
  color: var(--txt-2);
}
.btn:nth-child(2):hover::before {
  width: 100%;
}
.btn:nth-child(2)::before {
  width: 0;
  left: 0;
}
.btn::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--btn-1);
  transition: .5s;
  z-index: -1;
}
.btn:hover::before {
  width: 0;
}
.title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title h1 {
  font-size: 4rem;
  background: var(--txt-grad-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .title h1{
    font-size: 3.3rem;
  }
}

@media (max-width: 520px) {
  .btn-cont {
    gap: 1rem;
  }
  .title h1 {
    font-size: 3rem;
  }
}












