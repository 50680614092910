#Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--txt-2);
  opacity: 95%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  z-index: 101;
}
#Loader.Inactive_Loader {
  visibility: hidden;
  z-index: 0;
}
.spinner {
  position: relative;
  width: 20.2px;
  height: 20.2px;
}

.spinner div {
  animation: spinner-4t3wzl 1.875s infinite backwards;
  background-color: #5021ff;
  border-radius: 50%;
  height: 100%;
  position: absolute;
  width: 100%;
}

.spinner div:nth-child(1) {
  animation-delay: 0.15s;
  background-color: rgba(80,33,255,0.9);
}

.spinner div:nth-child(2) {
  animation-delay: 0.3s;
  background-color: rgba(80,33,255,0.8);
}

.spinner div:nth-child(3) {
  animation-delay: 0.45s;
  background-color: rgba(80,33,255,0.7);
}

.spinner div:nth-child(4) {
  animation-delay: 0.6s;
  background-color: rgba(80,33,255,0.6);
}

.spinner div:nth-child(5) {
  animation-delay: 0.75s;
  background-color: rgba(80,33,255,0.5);
}

@keyframes spinner-4t3wzl {
  0% {
     transform: rotate(0deg) translateY(-200%);
  }

  60%, 100% {
     transform: rotate(360deg) translateY(-200%);
  }
}