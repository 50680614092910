/* Animate */
#contact .animate {
  background: #ffffff;
}
/* contact */
#contact {
  padding-bottom: 3rem;
  padding-top: 3rem;
  border-bottom: .5px solid var(--btn-2);
}
#contact .contents{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
#contact .contents .content-left {
  width: 50%;
  min-width: 33rem;
  position: relative;
  font-size: 1.1rem;
  margin-top: 2rem;
}
#contact .contents .content-left form .icon-cont {
  display: none;
}
#contact .contents .content-left form label .btn {
  display: none;
}
#contact .btn-cont .nohover {
  display: none;
}
#contact .contents .content-right {
  position: absolute;
  top: 0%;
  right: -100%;
  width: 90%;
}
#contact .contents .content-right div {
  position: relative;
  width: 100%;
}
#contact .contents .content-right img {
  width: 100%;
}
@media (max-width: 601px) {
  #contact .contents .content-left {
    width: 100%;
    min-width: unset;
  }
}