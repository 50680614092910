/* Products Page */
#Products {
  padding: 5rem 5% 2rem;
  border-bottom: 0.5px solid var(--btn-2);
}
.Products-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#Products .btn-cont {
  gap: .8%;
}
#Products .btn-cont .btn {
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
}
.Products-container .Prod_cart {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--bg-4);
  border-radius: .5rem;
  gap: 2%;
}
.Products-container .Prod_cart .img-bg {
  background: var(--btn-5);
}
.Products-container .Prod_cart .img-cont {
  padding: 0.5rem 3rem;
  border-radius: 0.4rem;
  width: 26rem;
  height: 18rem;
  display: flex;
  position: relative;
}
.yt_cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 65px;
  cursor: pointer;
}
.yt_cont img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Products-container .Prod_cart .img-cont img {
  width: 100%;
  height: 100%;
  align-self: center;
  object-fit: contain;
}
.Products-container .subtexts {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  gap: 1rem;
  padding-right: 2.5rem;
  height: 100%;
  width: 100%;
}
.Products-container .subtexts h2 {
  font-weight: 500;
}
.Products-container .subcolor {
  color: var(--btn-6);
  font-weight: 500;
}
.Products-container .subtexts .btn-cont {
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 991px) {
  .Products-container .subtexts {
    padding: 1rem 2rem 1rem 0;
  }
  .Products-container .subtexts .btn-cont {
    gap: 3%;
  }
}
@media (max-width: 768px) {
  .Products-container .Prod_cart .img-cont {
    background-color: transparent;
  }
}
@media (max-width: 601px) {
  #Products {
    padding: 5rem 2% 0;
  }
  .Products-container .Prod_cart {
    flex-direction: column;
  }
  .Products-container .Prod_cart .img-cont {
    width: 100%;
    justify-content: center;
    display: flex;
    background: var(--btn-5);
  }
  .Products-container .subtexts {
    padding: 1rem 2rem;
  }
  .Products-container .btn {
    font-size: .8rem;
    padding: 0.5rem;
    min-width: 8rem;
  }
}
@media (max-width: 520px) {
  #Products {
    padding: 5rem 5% 0;
  }
  .Products-container .Prod_cart {
    flex-direction: column;
  }
  .Products-container .Prod_cart .img-cont {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .Products-container .subtexts {
    padding: 1rem 2rem;
  }
  .Products-container .subtexts h2 {
    text-align: center;
  }
}
@media (max-width: 813px) {
  #Products {
    padding: 5rem 4% 0;
  }
}

@media (max-width: 768px) {
  .Products-container .btn {
    font-size: 1rem;
    padding: .5rem .8rem;
  }
  .subtexts h2 {
    font-size: 1.3rem;
  }
  .subtexts p {
    font-size: .9rem;
  }
  #Products .btn-cont .btn {
    min-width: 0rem;
    padding: 0.5rem 0.7rem;
    font-size: .7rem;
    letter-spacing: 0;
  }
  #Products .btn-cont {
    gap: 1.8%;
  }
}