/* Animation */
#customers .ccart .animate  {
  background: var(--txt-2);
  height: 97%;
  top: 1.5%;
  width: 98%;
  right: 1%;
}
#customers .title .animate,
#customers .subtitle .animate {
  background: var(--btn-8);
}

/* Customers */
#customers {
  position: relative;
  background-image: url("../../img/customers.png");
  background-position: top;
  background-size: cover;
  padding-bottom: 15rem;
  background-color: var(--bg-color);
}
#customers .cards-cont {
  display: flex;
  justify-content: center;
  height: 20rem;
  gap: 2%;
  margin-bottom: 2rem;
}
.cards-cont .ccart {
  position: relative;
  width: 31%;
  min-width: 15rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--txt-1);
  padding: 0rem 1rem 1rem;
  border-radius: .5rem;
  background: var(--txt-2);
  transition: .25s ease-in-out;
  z-index: 99;
}
.cards-cont .ccart:hover {
  margin-top: -.5rem;
}
.cards-cont .ccart img {
  width: 20%;
  margin-bottom: 1.1rem;
}
.cards-cont .ccart h2 {
  font-weight: 500;
}
.subtitle {
  margin-bottom: 3rem;
  position: relative;
}
.subtitle h3 {
  font-size: 2.5rem;
  color: var(--txt-2);
}
.subtitle h2 {
  font-size: 2.9rem;
  background: var(--txt-grad-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 991px) {
  .cards-cont .ccart p {
    font-size: .9rem;
  }
}
@media (max-width: 813px) {
  #customers .cards-cont {
    align-items: center;
    flex-direction: column;
    height: max-content;
    gap: 1rem;
  }
  .cards-cont .ccart {
    width: 100%;
    padding: 1rem;
  }
  .cards-cont .ccart:hover {
    margin: unset;
  }
  .cards-cont .ccart .icon{
    width: 50%;
  }
}
@media (max-width: 768px) {
  .customer-container h3{
    font-size: 2rem;
  }
}
