/* Animation */
#about .animate {
  background: #ffffff;
}
/* about */
#about {
  border-bottom: .5px solid var(--btn-2);
}
#about .container {
  padding-top: 5rem;
  padding-bottom: 4rem;
  height: 100%;
}
#about .contents {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 9rem;
  justify-content: space-between;
  align-items: center;
}
#about .contents .content-left {
  position: relative;
  width: 42%;
  min-width: 30rem;
  font-size: 1.1rem;
}
#about .contents .content-right {
  position: relative;
  width: 55%;
  min-width: 40rem;
}
#about .contents .content-right img {
  width: 100%;
}
@media (max-width: 768px) {
  #about .contents .content-left {
    font-size: 1rem;
    min-width: 100%;
  }
}