
.container_p {
  width: 80%;
  margin: auto;
  padding: 20px;
  padding-top: 6rem;
}
.Privacy h1.priv {
  text-align: center;
  margin-bottom: 20px;
}
.Privacy p {
  padding-bottom: 20px;
  text-align: justify;
}
.Privacy table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.Privacy th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.Privacy th {
  background-color: #f2f2f2;
}
.Privacy ul {
  padding-left: 2rem;
}
@media screen and (max-width: 600px) {
  .Privacy table {
      overflow-x: auto;
      display: block;
  }
}