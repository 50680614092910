

:root {
  --bg-color: linear-gradient(180deg, #ffffff 0%, #d4dfef 100%);
  --bg-2: #FAFAFA;
  --bg-3: #ffffff;
  --bg-4: #F1F2FF;
  --txt-1: #424d60;
  --txt-2: #eef0f4;
  --txt-grad-1: linear-gradient(90deg, #b98fc8 0%, #26b3de 100%);
  --txt-grad-2: linear-gradient(13deg, #406aff 0%, #f7666f 100%);
  --btn-1: #5021FF;
  --btn-2: #f7666f;
  --btn-3: #d4dfef;
  --btn-4: #DCB3E3;
  --btn-5: #D1E1F0;
  --btn-6: #35B0D7;
  --btn-7: #fd9ca2;
}
#Carousel .slick-prev {
  left: 0px;
  z-index: 100;
}
#Carousel .slick-next {
  left: 40px;
}
.slick-next::before,
.slick-prev::before {
  font-size: 2rem;
  color: var(--btn-1);
}
#Carousel p {
  font-size: 3rem;
  font-weight: 500;
  background: var(--txt-grad-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#Carousel img {
  margin-top: -2rem;
  width: "100%";
  max-height: 35rem;
  max-width: max-content;
}