/* Home */

#home.start_animate span.animate {
  width: 0;
}
#home {
  position: relative;
  background: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
}
.home-left {
  position: relative;
  width: 50%;
  min-width: 30rem;
  height: 100%;
  padding-top: 4rem;
  z-index: 99;
}
.home-left h1 {
  font-size: 5.5rem;
  background: var(--txt-grad-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: -2rem;
  position: relative;
}
.home-left h3 {
  font-size: 2.1rem;
  color: var(--txt-1);
  font-weight: 400;
  position: relative;
  padding-top: .5rem;
}
.home-left h2 {
  position: relative;
  font-size: 3.3rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  animation: homeBgText 8s linear infinite;
  animation-delay: 2s;
  color: transparent;
  -webkit-text-stroke: .7px var(--btn-1);
  background-image: var(--txt-grad-2);
  background-repeat: no-repeat;
  background-position: -25rem 0;
}
.home-left h2::before {
  content: '';
  position: absolute;
  top: 10%;
  left: 0;
  width: 0;
  height: 70%;
  border-right: 2px solid var(--btn-1);
  z-index: -1;
  animation:   homeCursorText 8s linear infinite;
  animation-delay: 2s;
}

.home-left .text-animate {
  position: relative;
  width: fit-content;
  padding-left: .5rem;
  width: 25rem;
}

.home-left p {
  max-width: 33rem;
  margin-bottom: 2rem;
  color: var(--txt-1);
}
.social {
  position: relative;
  display: flex;
  gap: 1.1rem;
  align-items: center;
  width: 25rem;
  height: 5rem;
  margin-top: 3rem;
}
.social a {
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.4rem;
  border-radius: 50%;
  background: var(--txt-2);
  box-shadow: 4px 0px 14px rgba(0, 0, 0, .15);
  transition: .25s;
}
.social a:hover {
  margin-top: -.5rem;
}
.social a:last-child * {
  color: #28b333;
}
.social i {
  font-size: 1.4rem;
  display: inherit;
}
.home-right {
  position: absolute;
  top: 10%;
  left: 92%;
  width: 135%;
}
.home-right div {
  position: relative;
}
.hero {
  width: 100%;
}

@media (max-width: 991px) {
  .home-left h1 {
    font-size: 6.5rem;
  }
  .home-left h3 {
    font-size: 2.4rem;
  }
}

@media (max-width: 813px) {
  .home-left h1 {
    font-size: 6rem;
  }
}

@media (max-width: 768px) {
  .home-left h1 {
    font-size: 5rem;
  }
}

@media (max-width: 520px) {
  .home-left h1 {
    margin-bottom: .1rem;
  }
  .home-left h3 {
    font-size: 1.8rem;
  }
  .home-left p {
    max-width: 23rem;
  }
  .home-left .text-animate {
    width: 22rem;
  }
  .home-left h2 {
    background-position: -22rem 0;
    font-size: 3.1rem;
    animation: none;
    animation: homeBgText520 8s linear infinite;
  }
  .home-left h2::before {
    animation-delay: 0s;
  }
}
@media (max-width: 412px) {
  .home-left p {
    font-size: .9rem;
    max-width: 20rem;
  }
  #home .btn-cont a.btn {
    padding: .5rem;
    font-size: 1rem;
  }
  .home-left h3 {
    font-size: 1.7rem;
  }
  .home-left .text-animate {
    width: 20rem;
  }
  .home-left h2 {
    background-position: -20rem 0;
    font-size: 2.9rem;
    animation: none;
    animation: homeBgText412 8s linear infinite;
  }
  .home-left h2::before {
    animation-delay: .09s;
  }
}
@media (max-width: 371px) {
}

/*KEYFRAMES ANIMATION*/
@keyframes homeBgText {
  0%,10%,100% {
    background-position: -25rem 0;
  }
  65%,85% {
    background-position: 0 0;
  }
}
@keyframes homeCursorText {
  0%,10%,100% {
    width: 0;
  }
  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }

  74%,
  81%{
    opacity: 0;
  }
}


@keyframes homeBgText520 {
  0%,10%,100% {
    background-position: -22rem 0;
  }
  65%,85% {
    background-position: 0 0;
  }
}


@keyframes homeBgText412 {
  0%,10%,100% {
    background-position: -20rem 0;
  }
  65%,85% {
    background-position: 0 0;
  }
}

