/* Animation */
.isCart .animate {
  display: none;
}

/* Form */
form{
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
}
form .input-1,
form .input-2{ 
  position: relative;
  width: 100%;
  height: 3.4rem;
  display: flex;
  gap: 1.2rem;
  justify-content: space-between;
}
form input {
  flex: 1;
  min-width: 2rem;
  background: transparent;
  border: .1rem solid var(--btn-4);
  border-radius: .2rem;
  padding-left: 1rem;
  font-size: 1rem;
  color: var(--txt-1);
}
form .txtarea_cont {
  width: 100%;
  position: relative;
}
form textarea {
  position: relative;
  width: 100%;
  height: 16rem;
  background: transparent;
  border: .1rem solid var(--btn-4);
  border-radius: .2rem;
  padding: 1rem;
  font-size: 1rem;
  color: var(--txt-1);
}
#form .btn {
  position: relative;
  width: 14rem;
}
@media (max-width: 520px) {
  form .input-1,
  form .input-2 {
    flex-direction: column;
    height: 7.2rem;
  }
}