header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: .5rem 4%;
  display: flex;
  background: #ffffff;
  z-index: 200;
  align-items: center;
  justify-content: space-between;
  color: var(--txt-1);
  height: 5rem;
}
#menu-icon {
  display: none;
}
#menu-icon i{
  font-size: 31px;
  color: var(--txt-1);
}
header .logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 10rem;
  margin-right: 1.5rem;
  position: relative;
}
.telefone {
  position: absolute;
  bottom: -1.2rem;
  left: 0;
  right: 0;
  text-align: end;
}
.telefone * {
  color: #4F46E5;
}
.telefone a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.telefone .phone_icon {
  display: flex;
  padding-right: 2px;
}
.logo img {
  width: 2.3rem;
}
.logo p {
  font-size: 2.3rem;
  font-family: "conthrax";
  background: var(--txt-grad-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  min-width: max-content;
}
nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
nav ul {
  display: flex;
  justify-content: space-between;
  width: 43rem;
  font-size: 15px;
  color: inherit;
}
nav ul a {
  color: var(--txt-1);
  transition: .25s;
}
nav ul a:hover {
  color: var(--btn-1);
}
nav ul .acart{
  position: relative;
}
nav ul a:last-child span{
  position: absolute;
  top: -0.5rem;
  right: -1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  border: .1rem solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8rem;
  border-radius: 50%;
}

@media (max-width: 1120px) {
  header {
    padding: .5rem 2%;
  }
  .logo p {
    font-size: 1.9rem;
  }
  nav ul {
    width: 100%;
    max-width: 40rem;
  }
  nav ul a {
    font-size: 15px;
  }
  header .logo img {
    width: 2rem;
  }
  .telefone {
    bottom: -1.4rem;
  }
}
@media (max-width: 991px) {
  header .logo p {
    font-size: 1.7rem;
  }
  nav ul {
    margin-left: 0rem;
  }
  header .logo {
    gap: .8rem;
  }
  header .logo img {
    width: 1.5rem;
  }
  nav ul a {
    font-size: 13px;
  }
  nav ul a:last-child span {
    font-size: 10px;
    width: 17px;
    height: 17px;
    right: -1rem;
  }
}
@media (max-width: 813px) {
  #menu-icon {
    display: block ;
  }
  nav {
    position: absolute;
    display: block;
    left: -100%;
    top: 0;
    width: 100%;
    margin-top: 4rem;
    padding: 2rem 4%;
    background: var(--btn-3);
    z-index: 100;
    transition: .25s ease;
    transition-delay: .25s;
  }
  nav.active {
    left: 0;
    transition-delay: 0s;
  }
  nav .active-nav {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--btn-1);
    border-top: .1rem solid rgba(0, 0, 0, .2);
    z-index: 100;
    transition: .25s ease;
    transition-delay: 0s;
  }
  nav.active .active-nav {
    left: 100%;
    transition-delay: .25s;
  }
  nav ul {
    display: block;
  }
  nav a {
    display: block;
    font-size: 1.1rem !important;
    margin: 1.5rem 0;
    transition: .25s ease;
    transition-delay: 0s;
  }
  nav ul a:last-child span {
    left: 3.4rem;
    top: -0.5rem;
  }
}