#footer_contactus .footer-links {
  display: flex;
}
#footer_contactus .footer-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  flex-wrap: wrap;
}
#footer_contactus .phone_div a .footerPhone {
  font-size: 1.1rem;
  white-space: nowrap;
}
#footer_contactus .footer-copy {
  padding-bottom: 2rem;
}
